import { RouterHelper } from '@/router/routerHelper'
import { Component, Vue } from 'vue-property-decorator'

export enum LearningTypePage {
  Normal = 1,
  Test = 2,
  Seminar = 3,
  Entrance = 4,
  Assist = 5,
  Custom = 6,
  NormalV1 = 7,
}

@Component
export default class LearningType extends Vue {
  protected isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  protected callApiV3 =
    this.isV3 && [LearningTypePage.Custom, LearningTypePage.Normal].includes(this.currentLearningType)

  protected historyModeV3 = [
    LearningTypePage.Normal,
    LearningTypePage.Custom,
    LearningTypePage.Entrance,
    LearningTypePage.Assist,
  ]

  protected historyModeV1 = [LearningTypePage.Test, LearningTypePage.Seminar, LearningTypePage.NormalV1]

  protected get currentLearningType(): number {
    // StudyUnitにはパスパラメータとしてlearningTypeを渡すことでメニューモードを判別している
    // TODO: 生徒側と合わせてClassModeをcookieに持つようにするか？
    const type = new RouterHelper(this.$route).persePathes().third
    switch (type) {
      case 'normal':
        return LearningTypePage.Normal
      case 'test':
        return LearningTypePage.Test
      case 'seminar':
        return LearningTypePage.Seminar
      case 'entrance':
        return LearningTypePage.Entrance
      case 'assist-material':
        return LearningTypePage.Assist
      case 'custom':
        return LearningTypePage.Custom
      case 'normal-v1':
        return LearningTypePage.NormalV1
      default:
        return 0
    }
  }
}
