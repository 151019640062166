





























import { Component, Prop, Mixins } from 'vue-property-decorator'
import RatioBarText from '@/components/atoms/RatioBarText.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import { LessonResultConfigType, LessonResultStudyContentType } from '@/models/api/lessonResults'
import { RouterHelper } from '@/router/routerHelper'

@Component({
  components: {
    RatioBarText,
  },
})
export default class HistoryLearningResultStudyContent extends Mixins(StyleSubjectBackground) {
  @Prop()
  lessonConfig!: LessonResultConfigType

  @Prop()
  studyContents!: LessonResultStudyContentType[]

  @Prop({ default: false })
  isTeacher?: boolean

  @Prop()
  subjectCode!: string

  // URLから授業種別を判別(normal/test/seminar/entrance)
  // 現在のURLが履歴系のURL(/history/normal/~)である事を想定している
  private get learningType() {
    const type = new RouterHelper(this.$route).persePathes().third
    return ['normal', 'test', 'seminar', 'entrance'].includes(type) ? type : ''
  }

  get barStyleSet(): any {
    const _: any = this
    return function (code: string) {
      let fillColor = null
      let lineColor = null

      if (_.isTeacher) {
        fillColor = 'var(--color-blue)'
        lineColor = 'var(--color-blue-3)'
      } else {
        const subjectStyle = _.getSubjectStyle(code)
        fillColor = subjectStyle['--subject-bg-color']
        lineColor = subjectStyle['--subject-linear-gradiant']
        console.log(subjectStyle)
      }
      return {
        '--bar-fill-color': fillColor,
        '--bar-line-color': lineColor,
      }
    }
  }

  // 理解度(範囲の場合は平均値)
  private predictedScore(studyContent: LessonResultStudyContentType): number {
    if (this.lessonConfig.isRangePredictedScore) {
      const min = studyContent.minPredictedScore || 0
      const max = studyContent.maxPredictedScore || 100
      return (min + max) / 2
    }
    return studyContent.medianPredictedScore || 50
  }

  // 理解度に表示する文字列
  private predictedText(studyContent: LessonResultStudyContentType): string {
    if (this.lessonConfig.isRangePredictedScore) {
      const min = studyContent.minPredictedScore || 0
      const max = studyContent.maxPredictedScore || 100
      return `${min}-${max}%`
    }
    const median = studyContent.medianPredictedScore || 50
    return `${median}%`
  }

  private contentUrl(studyContent: LessonResultStudyContentType): string {
    const baseUrl = this.isTeacher
      ? `/teacher/history/${this.learningType}/study-unit`
      : `/student/history/${this.learningType}/study-unit`
    return `${baseUrl}/${studyContent.id}?prevPage=${this.$route.name}`
  }

  private drillResultUrl(resultDrillId: number): string {
    return this.isTeacher
      ? `/teacher/history/drillResult/${resultDrillId}?prevPage=${this.$route.name}`
      : `/student/history/drillResult/${resultDrillId}?prevPage=${this.$route.name}`
  }
}
