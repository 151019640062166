





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LabelBase extends Vue {
  @Prop()
  colortype?: string

  @Prop()
  radius?: string

  get classObject(): { [key: string]: boolean } {
    const { colortype, radius } = this
    return {
      // スタイル指定 同色でも意味が異なる場合は増やす
      'label-base__style-default': !colortype,
      'label-base__style-alert': colortype === 'alert',
      'label-base__style-information': colortype === 'information',
      'label-base__style-gray': colortype === 'gray',
      'label-base__style-light-gray': colortype === 'light-gray',
      'label-base__style-dark-gray': colortype === 'dark-gray',
      'label-base__style-student-alert': colortype === 'student-alert',
      'label-base__style-required': colortype === 'required',
      // 角丸指定
      'label-base__radius-default': !radius,
      'label-base__radius-small': radius === 'small',
      'label-base__radius-none': radius === 'none',
    }
  }
}
